<template>
  <div class="bg-white w-full rounded-lg mt-1">
    <div class="p-7">
      <!-- Personal Information -->
      <div>
        <TitleWrapper
          :filter-option="false"
          title="PERSONAL_INFO"
          tooltip-title="PERSONAL_INFO"
          :display-breadcrumb="false"
        ></TitleWrapper>
        <div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.Name`) }}:</div>
            <div class="text-menu capitalize">
              {{ currentUser.first_name }} {{ currentUser.last_name }}
            </div>
          </div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.Gender`) }}:</div>
            <div class="text-menu">{{ currentUser.gender || $t(`dashboard.SP_N_A`) }}</div>
          </div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.Blood Group`) }}:</div>
            <div class="text-menu">
              {{
                (currentUser.profile && currentUser.profile.blood_group) || $t(`dashboard.SP_N_A`)
              }}
            </div>
          </div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.Date of Birth`) }}:</div>
            <div class="text-menu">
              {{
                (currentUser.profile && currentUser.profile.date_of_birth) || $t(`dashboard.SP_N_A`)
              }}
            </div>
          </div>
          <div class="mt-5 flex font-roboto font-base mb-12">
            <div class="w-40">{{ $t(`dashboard.Nationality`) }}:</div>
            <div class="text-menu">
              {{
                (currentUser.profile && currentUser.profile.nationality) || $t(`dashboard.SP_N_A`)
              }}
            </div>
          </div>
        </div>
      </div>
      <!-- Contact Information -->
      <div>
        <div
          v-i18n="title"
          class="pb-2.5 font-medium font-roboto text-xl text-label-text border-b border-primary-grey"
        >
          CON_INFO
        </div>
        <div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.Phone`) }}:</div>
            <div class="text-menu">{{ currentUser.phone || $t(`dashboard.SP_N_A`) }}</div>
          </div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.SP_H2_T2`) }}:</div>
            <div class="text-menu">{{ currentUser.email || $t(`dashboard.SP_N_A`) }}</div>
          </div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.Address`) }}:</div>
            <div class="text-menu">
              {{ (currentUser.profile && currentUser.profile.address) || $t(`dashboard.SP_N_A`) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <StaffModal
      v-if="editProfile"
      :modal="editProfile"
      :edit="currentUser"
      @toggle="toggleStaffModal"
    ></StaffModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import TitleWrapper from '@components/TitleWrapper.vue'
import StaffModal from '@/src/router/views/admin/modals/StaffModal.vue'

export default {
  components: {
    TitleWrapper,
    StaffModal,
  },
  data() {
    return {
      currentUser: null,
      dashboard: 'dashboard',
      title: 'title',
      editProfile: false,
    }
  },
  page: {
    title: 'User Profile',
    meta: [{ name: 'description', content: 'Profile for User of MyQampus' }],
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
    }),
    ...mapGetters('auth', ['userImage']),
  },
  watch: {
    currentUser: {
      immediate: true,
      handler() {
        this.setRightbarData()
      },
    },
    showModal: {
      handler() {
        this.toggleStaffModal()
      },
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('auth.currentUser'))
  },
  methods: {
    toggleStaffModal() {
      this.editProfile = !this.editProfile
    },
    setRightbarData() {
      const rightBarContent = {
        header: {
          title: 'Profile Details',
          buttons: [
            {
              title: 'Edit Profile',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
              permissions: { roles: ['staff'] },
            },
          ],
        },
        profileInfo: [
          {
            imageUrl: this.userImage || null,
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>
